import React, { ReactElement } from "react";

export default function NotFound(): ReactElement {
  return (
    <svg
      width="484"
      height="448"
      viewBox="0 0 484 448"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M228.659 448H6.56727C4.82552 448 3.15511 447.309 1.92351 446.079C0.691908 444.849 0 443.181 0 441.442V6.50091C0.0151649 4.7715 0.71376 3.11808 1.94372 1.90055C3.17369 0.683012 4.83544 -6.63023e-05 6.56727 4.82692e-09H228.659C229.521 4.82693e-09 230.375 0.169635 231.172 0.499222C231.968 0.82881 232.692 1.3119 233.302 1.92089C233.912 2.52989 234.396 3.25288 234.726 4.04857C235.056 4.84427 235.226 5.69708 235.226 6.55834V441.442C235.226 442.303 235.056 443.156 234.726 443.951C234.396 444.747 233.912 445.47 233.302 446.079C232.692 446.688 231.968 447.171 231.172 447.501C230.375 447.83 229.521 448 228.659 448ZM6.56727 0.229715C4.90272 0.232751 3.30721 0.894438 2.13018 2.06986C0.953163 3.24528 0.290574 4.83862 0.287534 6.50091V441.442C0.290574 443.104 0.953163 444.697 2.13018 445.873C3.30721 447.048 4.90272 447.71 6.56727 447.713H228.659C230.323 447.71 231.919 447.048 233.096 445.873C234.273 444.697 234.935 443.104 234.938 441.442V6.50091C234.935 4.83862 234.273 3.24528 233.096 2.06986C231.919 0.894438 230.323 0.232751 228.659 0.229715H6.56727Z"
        fill="#EBEBEB"
      />
      <path
        d="M477.432 448H255.341C253.599 448 251.929 447.309 250.697 446.079C249.466 444.849 248.774 443.181 248.774 441.442V6.50091C248.789 4.7715 249.488 3.11808 250.718 1.90055C251.948 0.683012 253.609 -6.63023e-05 255.341 4.82692e-09H477.432C479.174 4.82694e-09 480.845 0.690966 482.076 1.92089C483.308 3.15082 484 4.81896 484 6.55834V441.442C484 442.303 483.83 443.156 483.5 443.951C483.17 444.747 482.686 445.47 482.076 446.079C481.466 446.688 480.742 447.171 479.946 447.501C479.149 447.83 478.295 448 477.432 448ZM255.341 0.229715C253.677 0.232751 252.081 0.894438 250.904 2.06986C249.727 3.24528 249.065 4.83862 249.061 6.50091V441.442C249.065 443.104 249.727 444.697 250.904 445.873C252.081 447.048 253.677 447.71 255.341 447.713H477.432C479.097 447.71 480.693 447.048 481.87 445.873C483.047 444.697 483.709 443.104 483.712 441.442V6.50091C483.709 4.83862 483.047 3.24528 481.87 2.06986C480.693 0.894438 479.097 0.232751 477.432 0.229715H255.341Z"
        fill="#EBEBEB"
      />
      <path
        d="M84.4893 130.076L86.3526 133.831L90.516 134.44L87.5027 137.369L88.2158 141.504L84.4893 139.551L80.7744 141.504L81.4875 137.369L78.4741 134.44L82.6376 133.831L84.4893 130.076Z"
        fill="#EBEBEB"
      />
      <path
        d="M456.731 307.22L458.594 310.987L462.758 311.596L459.744 314.513L460.457 318.659L456.731 316.707L453.016 318.659L453.729 314.513L450.716 311.596L454.879 310.987L456.731 307.22Z"
        fill="#F5F5F5"
      />
      <path
        d="M383.006 416.437L384.87 420.193L389.022 420.802L386.008 423.731L386.721 427.866L383.006 425.913L379.291 427.866L379.993 423.731L376.991 420.802L381.143 420.193L383.006 416.437Z"
        fill="#F5F5F5"
      />
      <path
        d="M214.328 63.3437L215.018 64.745L216.571 64.9747C216.657 64.986 216.739 65.0217 216.806 65.0777C216.873 65.1337 216.923 65.2075 216.949 65.2907C216.976 65.3738 216.978 65.4628 216.956 65.5472C216.934 65.6316 216.888 65.7079 216.824 65.7672L215.673 66.8584L215.938 68.3974C215.952 68.4831 215.941 68.5708 215.908 68.651C215.875 68.7311 215.82 68.8006 215.75 68.8517C215.68 68.9027 215.597 68.9335 215.51 68.9405C215.424 68.9475 215.337 68.9305 215.259 68.8913L213.879 68.1562L212.499 68.8913C212.422 68.9305 212.335 68.9475 212.248 68.9405C212.162 68.9335 212.079 68.9027 212.008 68.8517C211.938 68.8006 211.884 68.7311 211.85 68.651C211.817 68.5708 211.807 68.4831 211.82 68.3974L212.074 66.8584L210.923 65.7672C210.859 65.7072 210.813 65.6299 210.792 65.5446C210.77 65.4593 210.773 65.3696 210.801 65.2862C210.829 65.2027 210.88 65.129 210.949 65.0738C211.017 65.0185 211.1 64.9842 211.188 64.9747L212.729 64.745L213.419 63.3437C213.447 63.2446 213.506 63.1572 213.588 63.095C213.67 63.0327 213.77 62.999 213.873 62.999C213.977 62.999 214.077 63.0327 214.159 63.095C214.241 63.1572 214.3 63.2446 214.328 63.3437Z"
        fill="#EBEBEB"
      />
      <path
        d="M24.3711 398.554L25.0612 399.944L26.6024 400.174C26.6899 400.186 26.7722 400.222 26.84 400.278C26.9079 400.335 26.9585 400.409 26.986 400.493C27.0136 400.577 27.017 400.667 26.996 400.752C26.9749 400.838 26.9302 400.916 26.8669 400.978L25.7168 402.057L25.9813 403.596C25.9949 403.682 25.9845 403.77 25.9513 403.85C25.9182 403.93 25.8635 403.999 25.7933 404.051C25.7231 404.102 25.6402 404.132 25.5536 404.139C25.467 404.146 25.3802 404.129 25.3027 404.09L23.9225 403.367L22.5424 404.09C22.4649 404.129 22.3781 404.146 22.2915 404.139C22.2049 404.132 22.122 404.102 22.0518 404.051C21.9816 403.999 21.9269 403.93 21.8938 403.85C21.8606 403.77 21.8502 403.682 21.8638 403.596L22.1283 402.057L20.9782 400.978C20.9151 400.917 20.8702 400.84 20.8486 400.755C20.8269 400.67 20.8294 400.581 20.8557 400.497C20.882 400.414 20.931 400.339 20.9974 400.282C21.0637 400.225 21.1447 400.187 21.2312 400.174L22.7724 399.944L23.474 398.554C23.5047 398.459 23.5647 398.377 23.6453 398.318C23.7259 398.26 23.8229 398.228 23.9225 398.228C24.0222 398.228 24.1192 398.26 24.1998 398.318C24.2804 398.377 24.3404 398.459 24.3711 398.554Z"
        fill="#F5F5F5"
      />
      <path
        d="M55.3908 48.5846L56.0809 49.9858L57.6336 50.2041C57.7202 50.2179 57.8011 50.2555 57.8675 50.3127C57.9338 50.3699 57.9829 50.4444 58.0092 50.5279C58.0354 50.6114 58.0379 50.7006 58.0162 50.7854C57.9946 50.8702 57.9497 50.9473 57.8866 51.0081L56.7365 52.1567L57.001 53.6842C57.0194 53.771 57.0123 53.8611 56.9805 53.9439C56.9487 54.0266 56.8936 54.0985 56.8219 54.1507C56.7502 54.2029 56.6649 54.2332 56.5762 54.2381C56.4876 54.243 56.3995 54.2222 56.3224 54.1781L54.9423 53.4545L53.5621 54.1781C53.4851 54.2222 53.3969 54.243 53.3083 54.2381C53.2197 54.2332 53.1344 54.2029 53.0627 54.1507C52.9909 54.0985 52.9359 54.0266 52.9041 53.9439C52.8723 53.8611 52.8652 53.771 52.8835 53.6842L53.1366 52.1567L51.9864 51.0081C51.9231 50.9466 51.8784 50.8685 51.8574 50.7829C51.8363 50.6972 51.8397 50.6074 51.8673 50.5236C51.8949 50.4398 51.9455 50.3654 52.0133 50.3089C52.0811 50.2524 52.1635 50.2161 52.251 50.2041L53.7922 49.9858L54.5282 48.5846C54.5652 48.5011 54.6256 48.4302 54.7022 48.3804C54.7788 48.3306 54.8682 48.3041 54.9595 48.3041C55.0509 48.3041 55.1403 48.3306 55.2169 48.3804C55.2935 48.4302 55.3539 48.5011 55.3908 48.5846Z"
        fill="#EBEBEB"
      />
      <path
        d="M53.5276 325.413C53.5299 325.716 53.4421 326.012 53.2754 326.265C53.1087 326.518 52.8706 326.715 52.5913 326.833C52.312 326.95 52.0041 326.982 51.7066 326.924C51.4091 326.867 51.1355 326.722 50.9204 326.509C50.7053 326.296 50.5585 326.023 50.4985 325.727C50.4385 325.43 50.4682 325.122 50.5836 324.843C50.699 324.563 50.8951 324.324 51.1469 324.155C51.3987 323.987 51.6949 323.897 51.9979 323.897C52.4016 323.897 52.789 324.056 53.0755 324.34C53.362 324.624 53.5245 325.01 53.5276 325.413Z"
        fill="#EBEBEB"
      />
      <path
        d="M271.524 134.256C271.526 134.559 271.439 134.856 271.272 135.108C271.105 135.361 270.867 135.559 270.588 135.676C270.309 135.793 270.001 135.825 269.703 135.768C269.406 135.71 269.132 135.565 268.917 135.352C268.702 135.139 268.555 134.867 268.495 134.57C268.435 134.274 268.465 133.966 268.58 133.686C268.696 133.406 268.892 133.167 269.143 132.999C269.395 132.83 269.691 132.74 269.994 132.74C270.195 132.739 270.393 132.777 270.579 132.852C270.764 132.928 270.933 133.039 271.075 133.18C271.218 133.321 271.33 133.489 271.407 133.674C271.485 133.858 271.524 134.056 271.524 134.256Z"
        fill="#EBEBEB"
      />
      <path
        d="M192.21 49.848C192.21 50.1501 192.121 50.4455 191.952 50.6967C191.784 50.9479 191.545 51.1437 191.266 51.2593C190.986 51.3749 190.679 51.4052 190.382 51.3462C190.085 51.2873 189.813 51.1418 189.599 50.9282C189.385 50.7145 189.239 50.4423 189.18 50.146C189.121 49.8497 189.152 49.5425 189.267 49.2634C189.383 48.9843 189.579 48.7457 189.831 48.5778C190.082 48.41 190.378 48.3204 190.681 48.3204C191.085 48.3234 191.473 48.4853 191.759 48.7711C192.045 49.057 192.207 49.4438 192.21 49.848Z"
        fill="#F5F5F5"
      />
      <path
        d="M194.634 422.15C199.178 421.079 201.992 416.532 200.919 411.995C199.847 407.457 195.294 404.646 190.75 405.717C186.206 406.788 183.392 411.335 184.464 415.873C185.537 420.411 190.09 423.221 194.634 422.15Z"
        fill="#F0F0F0"
      />
      <path
        d="M189.784 408.995C188.052 408.991 186.365 409.538 184.965 410.557C184.25 412.194 184.075 414.016 184.464 415.76C184.853 417.503 185.787 419.078 187.13 420.257C188.474 421.437 190.157 422.159 191.939 422.321C193.72 422.483 195.507 422.076 197.041 421.158C197.721 419.903 198.062 418.494 198.032 417.068C198.002 415.642 197.601 414.248 196.87 413.023C196.138 411.798 195.101 410.784 193.858 410.08C192.616 409.375 191.212 409.006 189.784 409.006V408.995Z"
        fill="#E6E6E6"
      />
      <path
        d="M124.318 267.204H54.3325V235.653L124.318 152.565H157.81V237.444H175.166V267.204H157.81V293.024H124.318V267.204ZM124.318 237.444V193.983L87.3299 237.444H124.318Z"
        fill="#35A6D4"
      />
      <path
        d="M187.139 223.409C187.139 197.152 191.869 178.775 201.331 168.277C210.793 157.779 225.212 152.534 244.588 152.542C253.889 152.542 261.529 153.69 267.51 155.987C272.943 157.942 277.918 160.987 282.128 164.935C285.677 168.318 288.683 172.227 291.042 176.524C293.376 181.01 295.134 185.772 296.275 190.697C298.875 201.219 300.173 212.02 300.139 222.857C300.139 247.911 295.895 266.246 287.407 277.862C278.919 289.478 264.301 295.29 243.553 295.298C231.913 295.298 222.509 293.445 215.34 289.739C208.113 285.972 202.026 280.345 197.708 273.44C194.373 268.356 191.777 261.399 189.922 252.571C187.995 242.971 187.063 233.199 187.139 223.409ZM225.254 223.5C225.254 241.112 226.807 253.122 229.912 259.531C233.017 265.94 237.53 269.16 243.449 269.191C245.328 269.212 247.192 268.86 248.933 268.156C250.674 267.452 252.258 266.41 253.593 265.09C256.461 262.357 258.57 258.038 259.919 252.134C261.269 246.231 261.943 237.042 261.943 224.569C261.943 206.237 260.387 193.913 257.274 187.596C254.161 181.279 249.491 178.124 243.265 178.132C236.916 178.132 232.316 181.352 229.464 187.791C226.611 194.231 225.208 206.134 225.254 223.5Z"
        fill="#35A6D4"
      />
      <path
        d="M382.017 267.204H312.032V235.652L382.017 152.565H415.509V237.444H432.923V267.204H415.509V293.023H382.017V267.204ZM382.017 237.444V193.982L345.041 237.444H382.017Z"
        fill="#35A6D4"
      />
      <g opacity="0.3">
        <path
          opacity="0.3"
          d="M185.644 104.083C185.644 104.509 185.518 104.924 185.281 105.278C185.044 105.631 184.708 105.907 184.314 106.069C183.92 106.231 183.487 106.273 183.07 106.189C182.652 106.105 182.269 105.9 181.969 105.598C181.668 105.297 181.464 104.913 181.383 104.495C181.301 104.078 181.345 103.646 181.51 103.254C181.674 102.861 181.952 102.527 182.307 102.292C182.662 102.057 183.079 101.933 183.505 101.936C183.787 101.936 184.066 101.991 184.326 102.099C184.587 102.207 184.823 102.366 185.022 102.565C185.221 102.765 185.378 103.002 185.485 103.262C185.592 103.523 185.646 103.802 185.644 104.083Z"
          fill="#35A6D4"
        />
        <path
          opacity="0.3"
          d="M38.3922 186.585C39.2307 186.585 39.9104 185.907 39.9104 185.069C39.9104 184.232 39.2307 183.553 38.3922 183.553C37.5537 183.553 36.874 184.232 36.874 185.069C36.874 185.907 37.5537 186.585 38.3922 186.585Z"
          fill="#35A6D4"
        />
        <path
          opacity="0.3"
          d="M289.214 407.995C290.414 407.995 291.388 407.023 291.388 405.824C291.388 404.626 290.414 403.654 289.214 403.654C288.013 403.654 287.04 404.626 287.04 405.824C287.04 407.023 288.013 407.995 289.214 407.995Z"
          fill="#35A6D4"
        />
        <path
          opacity="0.3"
          d="M341.487 319.67C342.326 319.67 343.006 318.991 343.006 318.154C343.006 317.317 342.326 316.638 341.487 316.638C340.649 316.638 339.969 317.317 339.969 318.154C339.969 318.991 340.649 319.67 341.487 319.67Z"
          fill="#35A6D4"
        />
        <path
          opacity="0.3"
          d="M442.321 46.6549C442.321 46.9548 442.231 47.2479 442.065 47.4972C441.898 47.7466 441.661 47.9409 441.383 48.0556C441.106 48.1704 440.801 48.2004 440.506 48.1419C440.212 48.0834 439.941 47.939 439.729 47.727C439.517 47.515 439.372 47.2448 439.313 46.9507C439.255 46.6566 439.285 46.3518 439.4 46.0747C439.515 45.7977 439.709 45.5609 439.959 45.3943C440.209 45.2277 440.502 45.1388 440.802 45.1388C441.205 45.1388 441.591 45.2986 441.876 45.5829C442.161 45.8672 442.321 46.2528 442.321 46.6549Z"
          fill="#35A6D4"
        />
        <path
          opacity="0.3"
          d="M153.153 16.08C153.153 16.3798 153.064 16.6729 152.897 16.9223C152.73 17.1716 152.493 17.3659 152.215 17.4807C151.938 17.5954 151.633 17.6254 151.338 17.5669C151.044 17.5084 150.773 17.364 150.561 17.152C150.349 16.94 150.204 16.6698 150.145 16.3757C150.087 16.0816 150.117 15.7768 150.232 15.4998C150.347 15.2227 150.541 14.9859 150.791 14.8194C151.041 14.6528 151.334 14.5638 151.634 14.5638C152.036 14.5668 152.421 14.7275 152.705 15.0112C152.989 15.2949 153.15 15.6788 153.153 16.08Z"
          fill="#35A6D4"
        />
        <path
          opacity="0.3"
          d="M273.859 95.63C274.698 95.63 275.378 94.9512 275.378 94.1139C275.378 93.2765 274.698 92.5977 273.859 92.5977C273.021 92.5977 272.341 93.2765 272.341 94.1139C272.341 94.9512 273.021 95.63 273.859 95.63Z"
          fill="#35A6D4"
        />
      </g>
      <path
        d="M116.901 95.6414C130.818 95.6414 142.1 84.3746 142.1 70.4763C142.1 56.5779 130.818 45.3111 116.901 45.3111C102.983 45.3111 91.7012 56.5779 91.7012 70.4763C91.7012 84.3746 102.983 95.6414 116.901 95.6414Z"
        fill="#35A6D4"
      />
      <path
        opacity="0.7"
        d="M116.901 95.6414C130.818 95.6414 142.1 84.3746 142.1 70.4763C142.1 56.5779 130.818 45.3111 116.901 45.3111C102.983 45.3111 91.7012 56.5779 91.7012 70.4763C91.7012 84.3746 102.983 95.6414 116.901 95.6414Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M108.217 51.4904C104.785 51.4799 101.388 52.1718 98.2339 53.5233C94.3579 57.7775 92.0574 63.2288 91.715 68.9702C91.3726 74.7116 93.0091 80.3969 96.3521 85.0804C99.6951 89.7639 104.543 93.1632 110.09 94.7127C115.636 96.2621 121.547 95.8684 126.838 93.597C130.118 89.9866 132.279 85.5027 133.056 80.6898C133.834 75.8768 133.196 70.9419 131.219 66.4842C129.243 62.0265 126.012 58.2379 121.921 55.5783C117.83 52.9187 113.053 51.5026 108.171 51.5019L108.217 51.4904Z"
        fill="#35A6D4"
      />
      <path
        opacity="0.2"
        d="M105.709 58.1406C105.709 58.5949 105.575 59.0391 105.322 59.4168C105.069 59.7946 104.71 60.089 104.289 60.2629C103.869 60.4368 103.407 60.4823 102.96 60.3936C102.514 60.305 102.104 60.0862 101.783 59.7649C101.461 59.4437 101.242 59.0344 101.153 58.5888C101.064 58.1432 101.11 57.6813 101.284 57.2615C101.458 56.8418 101.753 56.483 102.131 56.2306C102.509 55.9782 102.954 55.8435 103.409 55.8435C104.019 55.8435 104.604 56.0855 105.036 56.5163C105.467 56.9471 105.709 57.5314 105.709 58.1406Z"
        fill="#35A6D4"
      />
      <path
        opacity="0.2"
        d="M132.807 55.8435C132.807 56.2978 132.672 56.7419 132.419 57.1197C132.166 57.4975 131.807 57.7919 131.387 57.9657C130.966 58.1396 130.504 58.1851 130.058 58.0965C129.611 58.0078 129.201 57.789 128.88 57.4678C128.558 57.1465 128.339 56.7372 128.25 56.2916C128.162 55.846 128.207 55.3841 128.381 54.9644C128.555 54.5446 128.85 54.1859 129.228 53.9335C129.607 53.681 130.051 53.5463 130.506 53.5463C131.116 53.5463 131.701 53.7883 132.133 54.2191C132.564 54.6499 132.807 55.2342 132.807 55.8435Z"
        fill="#35A6D4"
      />
      <path
        opacity="0.2"
        d="M128.206 72.2451C128.206 72.9902 127.985 73.7185 127.57 74.3381C127.156 74.9576 126.567 75.4405 125.877 75.7256C125.188 76.0108 124.429 76.0854 123.698 75.94C122.966 75.7946 122.294 75.4358 121.766 74.909C121.238 74.3821 120.879 73.7108 120.734 72.98C120.588 72.2493 120.663 71.4918 120.948 70.8034C121.234 70.115 121.717 69.5266 122.338 69.1127C122.958 68.6987 123.687 68.4778 124.434 68.4778C125.434 68.4778 126.394 68.8747 127.101 69.5812C127.809 70.2877 128.206 71.2459 128.206 72.2451Z"
        fill="#35A6D4"
      />
      <path
        opacity="0.2"
        d="M116.222 82.9842C116.222 83.9792 115.926 84.9519 115.373 85.7792C114.819 86.6065 114.032 87.2513 113.112 87.632C112.191 88.0128 111.178 88.1124 110.201 87.9183C109.224 87.7242 108.326 87.2451 107.622 86.5415C106.917 85.8379 106.438 84.9415 106.243 83.9657C106.049 82.9898 106.149 81.9783 106.53 81.059C106.911 80.1398 107.557 79.3541 108.385 78.8013C109.214 78.2485 110.188 77.9535 111.184 77.9535C112.52 77.9535 113.801 78.4835 114.746 79.427C115.691 80.3704 116.222 81.65 116.222 82.9842Z"
        fill="#35A6D4"
      />
      <path
        d="M415.555 374.027C418.361 362.155 410.998 350.26 399.11 347.458C387.222 344.655 375.31 352.008 372.504 363.88C369.698 375.752 377.061 387.648 388.949 390.45C400.837 393.252 412.749 385.9 415.555 374.027Z"
        fill="#35A6D4"
      />
      <path
        opacity="0.3"
        d="M415.555 374.027C418.361 362.155 410.998 350.26 399.11 347.458C387.222 344.655 375.31 352.008 372.504 363.88C369.698 375.752 377.061 387.648 388.949 390.45C400.837 393.252 412.749 385.9 415.555 374.027Z"
        fill="white"
      />
      <path
        opacity="0.4"
        d="M408 351.853C403.097 350.375 397.832 350.643 393.105 352.612C388.378 354.58 384.482 358.126 382.083 362.644C379.684 367.163 378.93 372.373 379.95 377.385C380.97 382.397 383.701 386.899 387.677 390.124C389.739 390.749 391.882 391.066 394.037 391.065C398.603 391.064 403.057 389.652 406.787 387.022C410.517 384.393 413.341 380.675 414.871 376.378C416.401 372.082 416.562 367.418 415.331 363.027C414.101 358.636 411.54 354.733 408 351.853Z"
        fill="#35A6D4"
      />
      <path
        d="M372.92 375.583C349.308 386.678 366.917 395.235 400.19 383.29C431.002 372.229 442.745 358.285 415.142 362.615C416.626 368.416 378.038 383.427 372.92 375.583Z"
        fill="#35A6D4"
      />
      <path
        opacity="0.2"
        d="M407.735 152.565C376.808 173.434 336.771 201 325.719 267.204H323.361C334.115 201.735 372.69 173.515 403.629 152.565H407.735Z"
        fill="black"
      />
      <path
        d="M247.751 359.813C228.199 359.813 208.865 351.003 199.446 336.623C193.637 327.733 186.886 309.115 206.99 284.271L208.773 285.718C194.558 303.292 191.935 320.922 201.378 335.371C212.879 352.944 240.011 361.788 263.175 355.494C286.902 349.051 301.302 328.445 302.693 298.962C307.443 198.106 361.097 167.037 400.271 144.352C424.619 130.259 442.205 120.083 438.34 98.5933C437.8 95.6185 436.615 93.5626 434.694 92.2991C429.691 89.0027 419.915 91.2884 408.598 93.9186C386.389 99.0872 355.979 106.162 337.864 75.3922L339.854 74.2436C357.106 103.567 385.365 96.9853 408.08 91.7019C419.915 88.9568 430.128 86.5793 435.96 90.404C438.432 92.0235 439.951 94.5848 440.56 98.2143C444.712 121.278 425.608 132.35 401.398 146.362C362.673 168.782 309.652 199.484 304.959 299.122C303.51 329.674 288.489 351.049 263.738 357.768C258.524 359.155 253.147 359.843 247.751 359.813Z"
        fill="#35A6D4"
      />
      <path
        opacity="0.2"
        d="M247.751 359.813C228.199 359.813 208.865 351.003 199.446 336.623C193.637 327.733 186.886 309.115 206.99 284.271L208.773 285.718C194.558 303.292 191.935 320.922 201.378 335.371C212.879 352.944 240.011 361.788 263.175 355.494C286.902 349.051 301.302 328.445 302.693 298.962C307.443 198.106 361.097 167.037 400.271 144.352C424.619 130.259 442.205 120.083 438.34 98.5933C437.8 95.6185 436.615 93.5626 434.694 92.2991C429.691 89.0027 419.915 91.2884 408.598 93.9186C386.389 99.0872 355.979 106.162 337.864 75.3922L339.854 74.2436C357.106 103.567 385.365 96.9853 408.08 91.7019C419.915 88.9568 430.128 86.5793 435.96 90.404C438.432 92.0235 439.951 94.5848 440.56 98.2143C444.712 121.278 425.608 132.35 401.398 146.362C362.673 168.782 309.652 199.484 304.959 299.122C303.51 329.674 288.489 351.049 263.738 357.768C258.524 359.155 253.147 359.843 247.751 359.813Z"
        fill="white"
      />
      <path
        d="M314.183 48.24C319.482 48.2705 324.747 49.0952 329.801 50.6864C329.801 50.6864 342.453 72.2451 343.948 77.184C343.419 82.0539 335.196 90.0365 335.196 90.0365L314.183 48.24Z"
        fill="#35A6D4"
      />
      <path
        opacity="0.3"
        d="M314.183 48.24C319.482 48.2705 324.747 49.0952 329.801 50.6864C329.801 50.6864 342.453 72.2451 343.948 77.184C343.419 82.0539 335.196 90.0365 335.196 90.0365L314.183 48.24Z"
        fill="white"
      />
      <path
        d="M351.655 152.909C346.783 146.96 342.34 140.673 338.359 134.096C337.393 132.434 336.477 130.742 335.61 129.019C335.116 128.043 334.644 127.066 334.184 126.079C333.58 125.117 333.112 124.078 332.792 122.989C331.412 108.437 336.404 97.7204 331.642 86.0394L312.688 93.4362C312.688 93.4362 314.298 114.248 317.979 126.745C320.279 134.475 325.431 141.159 330.412 147.35C331.964 149.28 333.459 151.267 335.012 153.208C336.565 155.149 338.129 156.722 339.613 158.549C341.855 161.317 342.592 163.97 340.901 167.232L340.613 167.749C340.13 168.599 342.384 169.563 343.811 167.749C346.111 164.785 345.789 164.969 347.79 162.442C349.009 160.915 350.401 159.249 351.609 157.848C352.191 157.159 352.515 156.289 352.523 155.387C352.531 154.485 352.224 153.609 351.655 152.909Z"
        fill="#35A6D4"
      />
      <path
        opacity="0.7"
        d="M351.655 152.909C346.783 146.96 342.34 140.673 338.359 134.096C337.393 132.434 336.477 130.742 335.61 129.019C335.116 128.043 334.644 127.066 334.184 126.079C333.58 125.117 333.112 124.078 332.792 122.989C331.412 108.437 336.404 97.7204 331.642 86.0394L312.688 93.4362C312.688 93.4362 314.298 114.248 317.979 126.745C320.279 134.475 325.431 141.159 330.412 147.35C331.964 149.28 333.459 151.267 335.012 153.208C336.565 155.149 338.129 156.722 339.613 158.549C341.855 161.317 342.592 163.97 340.901 167.232L340.613 167.749C340.13 168.599 342.384 169.563 343.811 167.749C346.111 164.785 345.789 164.969 347.79 162.442C349.009 160.915 350.401 159.249 351.609 157.848C352.191 157.159 352.515 156.289 352.523 155.387C352.531 154.485 352.224 153.609 351.655 152.909Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M347.019 146.925C344.012 150.145 340.673 153.04 337.059 155.562C337.554 156.114 338.037 156.711 338.531 157.239C342.15 154.798 345.436 151.899 348.307 148.614L347.019 146.925Z"
        fill="#35A6D4"
      />
      <path
        opacity="0.3"
        d="M351.655 152.909L351.517 152.748C350.632 153.762 350.117 155.046 350.056 156.389C349.982 157.311 350.132 158.237 350.493 159.089L351.586 157.791C352.157 157.109 352.475 156.251 352.488 155.362C352.501 154.473 352.206 153.607 351.655 152.909Z"
        fill="#35A6D4"
      />
      <path
        d="M309.675 62.0228C307.62 65.0954 305.155 67.8737 302.348 70.2811C300.792 71.6207 299.086 72.7769 297.265 73.7268C296.308 74.2164 295.316 74.6349 294.297 74.9787L293.515 75.2314L293.262 75.3003L292.722 75.4496C292.389 75.5285 292.05 75.5823 291.71 75.6104C290.739 75.6845 289.763 75.5952 288.823 75.3463C287.636 75.01 286.5 74.5196 285.441 73.8876C284.59 73.3905 283.764 72.8499 282.968 72.2681C281.53 71.2 280.156 70.0495 278.851 68.8224C276.335 66.4419 274.027 63.8521 271.95 61.081C271.55 60.5412 271.352 59.8789 271.389 59.2084C271.426 58.5379 271.696 57.9014 272.152 57.4086C272.609 56.9157 273.224 56.5976 273.89 56.509C274.557 56.4203 275.233 56.5668 275.803 56.9232H275.895C278.609 58.646 281.347 60.4608 284.015 62.0803C285.372 62.8728 286.683 63.6768 287.983 64.297C288.576 64.6072 289.187 64.8833 289.812 65.124C290.201 65.2956 290.618 65.3967 291.042 65.4226C291.111 65.4226 291.042 65.3422 290.617 65.3537C290.483 65.3457 290.348 65.3457 290.214 65.3537L289.961 65.4111L290.341 65.2159C290.852 64.9413 291.347 64.6383 291.825 64.3085C292.854 63.5839 293.816 62.769 294.7 61.8735C296.709 59.7707 298.591 57.55 300.336 55.2233C301.291 54.1554 302.612 53.4828 304.039 53.3373C305.466 53.1918 306.895 53.5839 308.048 54.4368C309.2 55.2897 309.991 56.5418 310.267 57.9474C310.542 59.3529 310.282 60.8106 309.537 62.0343L309.675 62.0228Z"
        fill="#35A6D4"
      />
      <path
        opacity="0.7"
        d="M309.675 62.0228C307.62 65.0954 305.155 67.8737 302.348 70.2811C300.792 71.6207 299.086 72.7769 297.265 73.7268C296.308 74.2164 295.316 74.6349 294.297 74.9787L293.515 75.2314L293.262 75.3003L292.722 75.4496C292.389 75.5285 292.05 75.5823 291.71 75.6104C290.739 75.6845 289.763 75.5952 288.823 75.3463C287.636 75.01 286.5 74.5196 285.441 73.8876C284.59 73.3905 283.764 72.8499 282.968 72.2681C281.53 71.2 280.156 70.0495 278.851 68.8224C276.335 66.4419 274.027 63.8521 271.95 61.081C271.55 60.5412 271.352 59.8789 271.389 59.2084C271.426 58.5379 271.696 57.9014 272.152 57.4086C272.609 56.9157 273.224 56.5976 273.89 56.509C274.557 56.4203 275.233 56.5668 275.803 56.9232H275.895C278.609 58.646 281.347 60.4608 284.015 62.0803C285.372 62.8728 286.683 63.6768 287.983 64.297C288.576 64.6072 289.187 64.8833 289.812 65.124C290.201 65.2956 290.618 65.3967 291.042 65.4226C291.111 65.4226 291.042 65.3422 290.617 65.3537C290.483 65.3457 290.348 65.3457 290.214 65.3537L289.961 65.4111L290.341 65.2159C290.852 64.9413 291.347 64.6383 291.825 64.3085C292.854 63.5839 293.816 62.769 294.7 61.8735C296.709 59.7707 298.591 57.55 300.336 55.2233C301.291 54.1554 302.612 53.4828 304.039 53.3373C305.466 53.1918 306.895 53.5839 308.048 54.4368C309.2 55.2897 309.991 56.5418 310.267 57.9474C310.542 59.3529 310.282 60.8106 309.537 62.0343L309.675 62.0228Z"
        fill="white"
      />
      <path
        d="M267.637 54.4652L268.983 56.7623C268.983 56.7623 270.007 59.7716 272.065 60.3229L277.655 58.5197L277.367 58.0488C276.654 56.9691 276.735 54.8672 276.976 53.1214C277.218 51.3756 276.321 51.3181 275.654 51.7546C275.181 52.2976 274.849 52.9485 274.688 53.6497C274.413 53.2704 274.109 52.9133 273.779 52.5816L272.077 50.8817C271.72 50.5331 271.244 50.3323 270.745 50.3195C270.246 50.3067 269.76 50.4829 269.385 50.8128L268.005 52.0417C267.678 52.3442 267.463 52.7487 267.396 53.1891C267.329 53.6295 267.414 54.0794 267.637 54.4652Z"
        fill="#35A6D4"
      />
      <path
        opacity="0.7"
        d="M267.637 54.4652L268.983 56.7623C268.983 56.7623 270.007 59.7716 272.065 60.3229L277.655 58.5197L277.367 58.0488C276.654 56.9691 276.735 54.8672 276.976 53.1214C277.218 51.3756 276.321 51.3181 275.654 51.7546C275.181 52.2976 274.849 52.9485 274.688 53.6497C274.413 53.2704 274.109 52.9133 273.779 52.5816L272.077 50.8817C271.72 50.5331 271.244 50.3323 270.745 50.3195C270.246 50.3067 269.76 50.4829 269.385 50.8128L268.005 52.0417C267.678 52.3442 267.463 52.7487 267.396 53.1891C267.329 53.6295 267.414 54.0794 267.637 54.4652Z"
        fill="white"
      />
      <path
        d="M319.831 46.1955C313.631 47.8367 307.692 50.3381 302.188 53.6268C301.329 54.1635 300.655 54.9495 300.256 55.8794C299.857 56.8094 299.753 57.8388 299.956 58.8298C302.176 69.6723 307.225 84.1903 312.677 93.4363L338.106 82.9268C338.279 78.4474 332.103 63.9524 325.811 49.9399C324.684 47.4245 322.51 45.5523 319.831 46.1955Z"
        fill="#35A6D4"
      />
      <path
        opacity="0.8"
        d="M319.831 46.1955C313.631 47.8367 307.692 50.3381 302.188 53.6268C301.329 54.1635 300.655 54.9495 300.256 55.8794C299.857 56.8094 299.753 57.8388 299.956 58.8298C302.176 69.6723 307.225 84.1903 312.677 93.4363L338.106 82.9268C338.279 78.4474 332.103 63.9524 325.811 49.9399C324.684 47.4245 322.51 45.5523 319.831 46.1955Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M329.756 58.8183L324.707 57.1299C325.857 60.0817 329.917 63.8146 332.758 66.0084C331.838 63.7112 330.814 61.2992 329.756 58.8183Z"
        fill="#35A6D4"
      />
      <path
        d="M318.162 34.8247C316.058 30.8276 311.515 28.8177 306.063 29.2656C301.462 29.6561 297.391 34.3423 297.874 36.8691C298.357 39.396 302.222 40.4756 302.958 41.3486L299.772 43.6457C299.394 43.9177 299.076 44.2632 298.836 44.6613C298.596 45.0595 298.438 45.502 298.374 45.9624C298.31 46.4227 298.339 46.8913 298.461 47.3399C298.582 47.7886 298.793 48.2081 299.082 48.5731C300.427 50.273 302.199 52.0188 303.222 53.3052C312.032 53.0755 318.553 49.7216 320.911 46.4942C320.026 42.4052 320.21 38.7987 318.162 34.8247Z"
        fill="#35A6D4"
      />
      <path
        opacity="0.8"
        d="M318.162 34.8247C316.058 30.8276 311.515 28.8177 306.063 29.2656C301.462 29.6561 297.391 34.3423 297.874 36.8691C298.357 39.396 302.222 40.4756 302.958 41.3486L299.772 43.6457C299.394 43.9177 299.076 44.2632 298.836 44.6613C298.596 45.0595 298.438 45.502 298.374 45.9624C298.31 46.4227 298.339 46.8913 298.461 47.3399C298.582 47.7886 298.793 48.2081 299.082 48.5731C300.427 50.273 302.199 52.0188 303.222 53.3052C312.032 53.0755 318.553 49.7216 320.911 46.4942C320.026 42.4052 320.21 38.7987 318.162 34.8247Z"
        fill="white"
      />
      <path
        d="M313.838 37.3056C314.464 38.9074 314.6 40.6584 314.229 42.3373C313.858 44.0162 312.997 45.5476 311.754 46.7379C310.512 47.9283 308.944 48.7241 307.249 49.0248C305.554 49.3255 303.808 49.1176 302.231 48.4274C300.654 47.7372 299.318 46.5957 298.391 45.1471C297.463 43.6985 296.987 42.0079 297.022 40.289C297.056 38.57 297.601 36.9 298.586 35.4899C299.571 34.0798 300.952 32.9929 302.555 32.3667C304.708 31.5294 307.105 31.5793 309.22 32.5052C311.335 33.4312 312.996 35.1577 313.838 37.3056Z"
        fill="#263238"
      />
      <path
        d="M388.516 140.815C388.39 137.036 388.217 137.369 388.114 134.188C388.045 132.235 388.033 130.064 388.022 128.192C388.018 127.303 387.707 126.442 387.141 125.756C386.575 125.07 385.789 124.599 384.916 124.425C383.398 124.115 381.869 123.827 380.316 123.506C378.326 123.081 376.359 122.621 374.416 122.059C372.897 121.622 371.402 121.14 369.919 120.6C368.435 120.06 366.767 119.451 365.226 118.739C363.409 117.97 361.615 117.143 359.843 116.281C357.854 115.339 355.887 114.352 353.92 113.352C346.352 100.845 346.191 92.5978 338.071 82.9268L320.635 90.806C320.635 90.806 333.448 113.502 342.166 123.127C347.192 128.663 355.07 131.167 362.098 132.982C367.17 134.279 372.311 135.279 377.452 136.232C379.453 136.6 381.627 136.737 383.352 137.932C384.629 138.909 385.504 140.318 385.813 141.894C385.89 142.216 385.959 142.538 386.02 142.859C386.239 143.824 388.597 143.089 388.516 140.815Z"
        fill="#35A6D4"
      />
      <path
        opacity="0.8"
        d="M388.516 140.815C388.39 137.036 388.217 137.369 388.114 134.188C388.045 132.235 388.033 130.064 388.022 128.192C388.018 127.303 387.707 126.442 387.141 125.756C386.575 125.07 385.789 124.599 384.916 124.425C383.398 124.115 381.869 123.827 380.316 123.506C378.326 123.081 376.359 122.621 374.416 122.059C372.897 121.622 371.402 121.14 369.919 120.6C368.435 120.06 366.767 119.451 365.226 118.739C363.409 117.97 361.615 117.143 359.843 116.281C357.854 115.339 355.887 114.352 353.92 113.352C346.352 100.845 346.191 92.5978 338.071 82.9268L320.635 90.806C320.635 90.806 333.448 113.502 342.166 123.127C347.192 128.663 355.07 131.167 362.098 132.982C367.17 134.279 372.311 135.279 377.452 136.232C379.453 136.6 381.627 136.737 383.352 137.932C384.629 138.909 385.504 140.318 385.813 141.894C385.89 142.216 385.959 142.538 386.02 142.859C386.239 143.824 388.597 143.089 388.516 140.815Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M379.672 123.356C378.982 123.207 378.292 123.058 377.59 122.886C377.648 126.86 375.785 133.705 375.106 135.784L377.291 136.198C378.723 132.06 379.526 127.732 379.672 123.356Z"
        fill="#35A6D4"
      />
      <path
        opacity="0.3"
        d="M388.068 128.192C388.064 127.305 387.755 126.445 387.191 125.759C386.628 125.073 385.845 124.602 384.974 124.425L383.824 124.206C384.008 125.512 384.678 126.701 385.698 127.537C386.355 128.143 387.142 128.591 387.999 128.847C388.068 128.64 388.068 128.41 388.068 128.192Z"
        fill="#35A6D4"
      />
      <path
        d="M312.216 37.3745C312.676 39.1203 310.721 40.9465 309.168 39.4419C307.719 37.8902 306.133 36.4718 304.43 35.2037C302.831 34.2044 304.959 32.4586 307.478 33.1363C308.552 33.4059 309.545 33.9326 310.371 34.671C311.196 35.4094 311.83 36.3372 312.216 37.3745Z"
        fill="white"
      />
      <path
        d="M312.343 92.8735C311.538 93.1721 313.01 94.5504 313.01 94.5504C313.01 94.5504 329.112 89.0487 338.888 83.3863C338.912 83.0435 338.853 82.7 338.717 82.3843C338.581 82.0687 338.371 81.7901 338.106 81.5715C329.739 85.8219 321.137 89.5953 312.343 92.8735Z"
        fill="#35A6D4"
      />
      <path
        opacity="0.5"
        d="M312.343 92.8735C311.538 93.1721 313.01 94.5504 313.01 94.5504C313.01 94.5504 329.112 89.0487 338.888 83.3863C338.912 83.0435 338.853 82.7 338.717 82.3843C338.581 82.0687 338.371 81.7901 338.106 81.5715C329.739 85.8219 321.137 89.5953 312.343 92.8735Z"
        fill="white"
      />
      <path
        d="M324.189 45.4375C327.364 47.0455 330.343 48.7339 333.39 50.606C334.874 51.5479 336.346 52.5012 337.807 53.5349C339.268 54.5686 340.717 55.6253 342.177 56.8198L342.718 57.2677L343.397 57.888C343.801 58.2493 344.185 58.6327 344.547 59.0365C344.915 59.4385 345.225 59.8291 345.524 60.1851C345.823 60.5412 346.145 60.9661 346.41 61.3337C347.482 62.8103 348.472 64.344 349.377 65.928C351.16 68.9997 352.698 72.2064 353.978 75.5185C354.214 76.1443 354.227 76.8326 354.012 77.4664C353.798 78.1002 353.371 78.6403 352.803 78.995C352.235 79.3496 351.562 79.4969 350.897 79.4117C350.233 79.3265 349.619 79.0142 349.159 78.5278L349.101 78.4589C346.801 76.0124 344.581 73.4396 342.35 71.0391C340.119 68.6386 337.853 66.2036 335.978 65.1355C333.367 63.516 330.458 61.8965 327.571 60.3115L318.876 55.4875C317.527 54.7746 316.518 53.5562 316.069 52.1001C315.621 50.644 315.77 49.0695 316.483 47.7231C317.197 46.3767 318.417 45.3686 319.875 44.9206C321.333 44.4725 322.91 44.6213 324.258 45.3341L324.189 45.4375Z"
        fill="#35A6D4"
      />
      <path
        opacity="0.8"
        d="M324.189 45.4375C327.364 47.0455 330.343 48.7339 333.39 50.606C334.874 51.5479 336.346 52.5012 337.807 53.5349C339.268 54.5686 340.717 55.6253 342.177 56.8198L342.718 57.2677L343.397 57.888C343.801 58.2493 344.185 58.6327 344.547 59.0365C344.915 59.4385 345.225 59.8291 345.524 60.1851C345.823 60.5412 346.145 60.9661 346.41 61.3337C347.482 62.8103 348.472 64.344 349.377 65.928C351.16 68.9997 352.698 72.2064 353.978 75.5185C354.214 76.1443 354.227 76.8326 354.012 77.4664C353.798 78.1002 353.371 78.6403 352.803 78.995C352.235 79.3496 351.562 79.4969 350.897 79.4117C350.233 79.3265 349.619 79.0142 349.159 78.5278L349.101 78.4589C346.801 76.0124 344.581 73.4396 342.35 71.0391C340.119 68.6386 337.853 66.2036 335.978 65.1355C333.367 63.516 330.458 61.8965 327.571 60.3115L318.876 55.4875C317.527 54.7746 316.518 53.5562 316.069 52.1001C315.621 50.644 315.77 49.0695 316.483 47.7231C317.197 46.3767 318.417 45.3686 319.875 44.9206C321.333 44.4725 322.91 44.6213 324.258 45.3341L324.189 45.4375Z"
        fill="white"
      />
      <path
        d="M356.703 81.2499L355.726 78.8034C355.726 78.8034 355.185 75.6793 353.241 74.8064L347.491 75.7138L347.686 76.2191C348.215 77.3677 347.813 79.4696 347.295 81.1465C346.778 82.8234 347.652 83.0417 348.388 82.7086C348.802 82.5248 349.205 81.7782 349.642 80.9857C349.856 81.385 350.098 81.7688 350.366 82.1343L351.781 84.0754C352.076 84.4776 352.513 84.7533 353.004 84.847C353.495 84.9408 354.003 84.8456 354.426 84.5807L355.99 83.5815C356.356 83.3313 356.626 82.9642 356.756 82.5405C356.886 82.1168 356.867 81.6617 356.703 81.2499Z"
        fill="#35A6D4"
      />
      <path
        opacity="0.8"
        d="M356.703 81.2499L355.726 78.8034C355.726 78.8034 355.185 75.6793 353.241 74.8064L347.491 75.7138L347.686 76.2191C348.215 77.3677 347.813 79.4696 347.295 81.1465C346.778 82.8234 347.652 83.0417 348.388 82.7086C348.802 82.5248 349.205 81.7782 349.642 80.9857C349.856 81.385 350.098 81.7688 350.366 82.1343L351.781 84.0754C352.076 84.4776 352.513 84.7533 353.004 84.847C353.495 84.9408 354.003 84.8456 354.426 84.5807L355.99 83.5815C356.356 83.3313 356.626 82.9642 356.756 82.5405C356.886 82.1168 356.867 81.6617 356.703 81.2499Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M319.335 58.6461L317.932 58.7609L309.317 79.5271C309.788 79.5609 310.261 79.5222 310.721 79.4122C310.721 79.4122 321.946 75.2314 325.339 73.6694C323.061 68.9717 319.335 58.6461 319.335 58.6461Z"
        fill="#35A6D4"
      />
      <path
        d="M303.613 64.0903C304.659 69.5186 306.603 74.7354 309.363 79.527C313.573 78.0798 320.589 75.3463 323.993 73.7842C321.705 68.8855 319.674 63.8709 317.909 58.7609C314.309 59.0021 306.408 62.0228 303.613 64.0903Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M312.159 68.5812C311.99 69.1429 311.659 69.6421 311.207 70.0158C310.755 70.3894 310.201 70.6207 309.617 70.6804C309.033 70.74 308.445 70.6253 307.926 70.3508C307.407 70.0763 306.982 69.6544 306.703 69.1383C306.425 68.6222 306.306 68.0353 306.361 67.4517C306.417 66.8681 306.645 66.3141 307.016 65.8597C307.387 65.4054 307.884 65.0711 308.446 64.8993C309.007 64.7274 309.607 64.7257 310.169 64.8943C310.922 65.1201 311.555 65.6352 311.928 66.3265C312.301 67.0179 312.384 67.8288 312.159 68.5812Z"
        fill="#35A6D4"
      />
      <path
        opacity="0.6"
        d="M314.356 64.6301C314.311 64.8218 314.209 64.9955 314.064 65.1284C313.918 65.2613 313.736 65.3472 313.541 65.3748C313.346 65.4024 313.147 65.3704 312.97 65.283C312.793 65.1956 312.647 65.0569 312.551 64.8851C312.455 64.7134 312.413 64.5165 312.43 64.3204C312.448 64.1243 312.525 63.9382 312.65 63.7864C312.776 63.6346 312.944 63.5243 313.134 63.4699C313.323 63.4155 313.525 63.4195 313.712 63.4815C313.945 63.556 314.141 63.7159 314.26 63.9291C314.38 64.1423 314.414 64.3928 314.356 64.6301Z"
        fill="#35A6D4"
      />
      <path
        opacity="0.6"
        d="M316.932 63.6308C316.905 63.7641 316.85 63.8902 316.772 64.0012C316.693 64.1122 316.592 64.2055 316.475 64.2752C316.358 64.345 316.228 64.3896 316.093 64.4062C315.958 64.4228 315.821 64.4111 315.691 64.3717C315.56 64.3323 315.44 64.2662 315.337 64.1776C315.233 64.089 315.15 63.9799 315.091 63.8572C315.033 63.7344 315 63.6009 314.996 63.465C314.993 63.3291 315.017 63.1939 315.069 63.068C315.105 62.9465 315.166 62.8334 315.247 62.7353C315.327 62.6371 315.427 62.5558 315.539 62.4961C315.651 62.4363 315.774 62.3993 315.9 62.387C316.027 62.3748 316.155 62.3877 316.276 62.4248C316.522 62.4993 316.729 62.6677 316.852 62.8934C316.975 63.1192 317.003 63.3841 316.932 63.6308Z"
        fill="#35A6D4"
      />
      <path
        opacity="0.5"
        d="M320.543 72.5782L309.905 76.7705L309.272 74.6801L319.899 70.4878L320.543 72.5782Z"
        fill="#35A6D4"
      />
      <path
        opacity="0.2"
        d="M261.851 232.207C261.826 238.895 261.18 245.566 259.919 252.134C258.562 258.038 256.453 262.357 253.593 265.09C252.261 266.411 250.679 267.453 248.939 268.157C247.2 268.861 245.337 269.213 243.46 269.191C237.549 269.191 233.036 265.971 229.923 259.531C229.376 258.374 228.911 257.181 228.532 255.959C221.957 261.598 215.835 267.742 210.221 274.336L204.114 281.526C207.401 284.853 211.193 287.64 215.351 289.784C222.513 293.483 231.913 295.336 243.552 295.344C245.6 295.344 247.578 295.286 249.499 295.171C256.54 284.766 262.367 273.59 266.866 261.863L283.945 217.39L261.851 232.207Z"
        fill="black"
      />
      <path
        d="M107.882 292.886L128.01 312.986L184.366 259.956C164.941 252.548 127.17 273.578 107.882 292.886Z"
        fill="#263238"
      />
      <path
        d="M178.076 362.948C171.646 356.516 157.948 342.848 157.948 342.848L211.05 286.568C218.422 305.922 197.363 343.675 178.076 362.948Z"
        fill="#263238"
      />
      <path
        d="M255.111 236.261L277.067 193.982L234.731 215.931C221.132 222.974 208.576 231.865 197.421 242.348L125.25 310.195L160.708 345.605L228.647 273.532C239.15 262.389 248.056 249.846 255.111 236.261Z"
        fill="#35A6D4"
      />
      <path
        opacity="0.6"
        d="M255.111 236.261L277.067 193.982L234.731 215.931C221.132 222.974 208.576 231.865 197.421 242.348L125.25 310.195L160.708 345.605L228.647 273.532C239.15 262.389 248.056 249.846 255.111 236.261Z"
        fill="white"
      />
      <path
        d="M210.026 275.749C218.22 275.749 224.863 269.115 224.863 260.932C224.863 252.749 218.22 246.116 210.026 246.116C201.832 246.116 195.189 252.749 195.189 260.932C195.189 269.115 201.832 275.749 210.026 275.749Z"
        fill="white"
      />
      <path
        d="M210.026 270.454C215.292 270.454 219.56 266.191 219.56 260.932C219.56 255.674 215.292 251.411 210.026 251.411C204.76 251.411 200.491 255.674 200.491 260.932C200.491 266.191 204.76 270.454 210.026 270.454Z"
        fill="#35A6D4"
      />
      <path
        opacity="0.3"
        d="M172.704 332.879L137.981 298.203L166.413 271.488L199.456 304.498L172.704 332.879Z"
        fill="#35A6D4"
      />
      <path
        d="M115.738 324.609C89.7449 326.711 81.1304 343.331 78.186 365.418C76.6908 376.685 76.0238 388.091 66.5581 394.683C65.9887 395.07 65.56 395.63 65.3361 396.28C65.1123 396.931 65.1054 397.636 65.3165 398.29C65.5277 398.945 65.9454 399.513 66.5072 399.911C67.069 400.308 67.7446 400.513 68.4329 400.495C103.328 399.404 119.602 382.348 121.65 375.227C121.112 378.949 120.151 382.599 118.786 386.104C118.535 386.722 118.486 387.404 118.645 388.052C118.805 388.7 119.166 389.281 119.675 389.713C120.185 390.144 120.818 390.404 121.485 390.455C122.151 390.506 122.817 390.346 123.386 389.997C133.174 383.875 145.457 372.596 146.274 354.3C139.074 344.112 115.738 324.609 115.738 324.609Z"
        fill="#35A6D4"
      />
      <path
        opacity="0.2"
        d="M115.738 324.609C89.7449 326.711 81.1304 343.331 78.186 365.418C76.6908 376.685 76.0238 388.091 66.5581 394.683C65.9887 395.07 65.56 395.63 65.3361 396.28C65.1123 396.931 65.1054 397.636 65.3165 398.29C65.5277 398.945 65.9454 399.513 66.5072 399.911C67.069 400.308 67.7446 400.513 68.4329 400.495C103.328 399.404 119.602 382.348 121.65 375.227C121.112 378.949 120.151 382.599 118.786 386.104C118.535 386.722 118.486 387.404 118.645 388.052C118.805 388.7 119.166 389.281 119.675 389.713C120.185 390.144 120.818 390.404 121.485 390.455C122.151 390.506 122.817 390.346 123.386 389.997C133.174 383.875 145.457 372.596 146.274 354.3C139.074 344.112 115.738 324.609 115.738 324.609Z"
        fill="white"
      />
      <path
        d="M150.311 362.144L108.676 320.566L130.85 315.777L155.107 340L150.311 362.144Z"
        fill="#35A6D4"
      />
    </svg>
  );
}
