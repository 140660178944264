import React, {ReactElement} from "react";
import {navigate} from "gatsby";
import LogoDark from "../icons/LogoDark";
import Typography from "../components/Typography";
import NotFound from "../icons/NotFound";
import Button from "../components/Button";
import "./404.scss";

export default function Page404(): ReactElement {
  return (
    <>
      <div className="pq-404_logo">
        <LogoDark/>
      </div>
      <div className="pq-404">
        <Typography variant="title_large" color="#000">
          Oops! Looks like you’re lost.
        </Typography>
        <div className="pq-404_vector">
          <NotFound/>
        </div>
        <Button size="lg" onClick={() => navigate("/")}>
          Back to Home
        </Button>
      </div>
    </>
  );
}
